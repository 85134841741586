<template>
  <div class="la-form-builder category-builder card">
    <fd-form class="modal-content-wrapper" @submit="submitForm">
      <modal-body>
        <div class="container fluid">
          <h3 class="mt-3 mb-4">
            {{
              mode === MODE.ADD ? "Create" : mode === MODE.EDIT ? "Edit" : ""
            }}
            Category
          </h3>

          <fd-input
            v-model="laDocForm.category"
            class="col-12 md-col-6 px-1 mb-2"
            label="Category Name"
            name="category"
            type="text"
            :validators="[validator.required]"
          >
          </fd-input>

          <!-- Document Fields -->
          <div class="document-fields px-1">
            <h4 class="mb-2">Document Fields</h4>

            <div class="row mxn-1">
              <document-field
                v-for="(field, index) in laDocForm.fields"
                :key="index"
                :title.sync="laDocForm.fields[index].title"
                :subTitle.sync="laDocForm.fields[index].subTitle"
                :isRequired.sync="laDocForm.fields[index].isCompulsory"
                class="col-12 sm-col-6 px-1 mb-2"
                @remove="removeField(laDocForm.fields[index], index)"
              ></document-field>
            </div>
          </div>

          <!-- Actions -->
          <div class="px-1 my-4">
            <button type="button" class="btn main bordered" @click="addField">
              <i class="fas fa-plus-circle mr-1"></i>Add Field
            </button>
          </div>
        </div>
      </modal-body>

      <!-- Footer Actions -->
      <modal-footer class="row justify-content-end p-2">
        <button type="button" class="btn mr-1" @click="$emit('cancel')">
          Cancel
        </button>
        <button class="btn main medium">
          <i
            class="fas mr-1"
            :class="{
              'fa-plus': this.mode === MODE.ADD,
              'fa-pen': this.mode === MODE.EDIT
            }"
          ></i>
          {{
            mode === MODE.ADD ? "Create" : mode === MODE.EDIT ? "Update" : ""
          }}
          Category
        </button>
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { LoanApplicationDocFormModel as LADocFormModel } from "@/models";
import { loanApplicationDocForm as LADocFormAPI } from "@/api";

const MODE = {
  ADD: "add",
  EDIT: "edit"
};

export default {
  components: {
    DocumentField: () =>
      import("@/components/LoanApplicationFormBuilder/DocumentField")
  },
  mixins: [],
  props: {
    mode: {
      type: String,
      default: MODE.add
    },
    categoryId: {
      type: [String, Number]
    }
  },
  data: function () {
    return {
      laDocForm: {},

      MODE,
      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      // Create mode
      if (this.mode === MODE.ADD) {
        this.laDocForm = {
          category: "",
          fields: [
            {
              title: "",
              subTitle: ""
            }
          ]
        };
      }
      // Edit mode
      else if (this.mode === MODE.EDIT) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.getCategory();
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
        }
      }
    },
    async getCategory() {
      try {
        let data = await LADocFormAPI.getLADocFormCategory(this.categoryId);
        this.laDocForm = LADocFormModel.getCategoryToEdit(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get category data. Please try again later."
        });
        throw error;
      }
    },
    addField() {
      // Create mode
      if (this.mode == MODE.ADD) {
        this.laDocForm.fields.push({
          title: "",
          subTitle: "",
          isCompulsory: false
        });
      }
      // Edit mode
      if (this.mode == MODE.EDIT) {
        this.laDocForm.fields.push({
          id: null,
          title: "",
          subTitle: "",
          isCompulsory: false
        });
      }
    },
    removeField(docField, index) {
      // Create mode
      if (this.mode == MODE.ADD) {
        this.removeFieldLocally(index);
      }

      // Edit mode
      if (this.mode == MODE.EDIT) {
        if (this.$isStringEmpty(docField.id)) {
          this.removeFieldLocally(index);
        } else {
          this.removeFieldFromDb(docField.id);
        }
      }
    },
    async removeFieldFromDb(id) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Remove Document Field",
        message:
          "Are you sure you want to remove this field? This action cannot be undone.",
        confirmText: "Remove"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await LADocFormAPI.deleteLADocFormField(id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "The document field has been removed successfully."
          });

          await this.getCategory();

          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to remove this field. Please try again later."
          });
        }
      }
    },
    removeFieldLocally(index) {
      this.laDocForm.fields = this.laDocForm.fields.filter((item, idx) => {
        return idx !== index;
      });
    },
    submitForm() {
      // Create mode
      if (this.mode == MODE.ADD) {
        this.$emit(
          "create",
          LADocFormModel.postCategoryPayload(this.laDocForm)
        );
      }
      // Edit mode
      else if (this.mode == MODE.EDIT) {
        this.$emit(
          "update",
          this.laDocForm.id,
          LADocFormModel.putCategoryPayload(this.laDocForm)
        );
      }
    }
  }
};
</script>

<style lang="scss">
.category-builder {
  .document-fields {
    margin-top: 48px;
  }
}
</style>
